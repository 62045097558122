.progress {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: $max-width;
    margin: 3rem auto;
}

progress {
    border-radius: $border-radius;
    width: calc(100% - 125px);
    margin-left: 1rem;

    @media (min-width: 1024px) {
        width: 50%;
    }
}

// background
progress::-webkit-progress-bar {
    background-color: $yellow2;
    border-radius: $border-radius;
}
progress::-webkit-progress-value {
    background-color: $yellow5;
    border-radius: $border-radius;
}

.progress__spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    margin-top: -1px;

    &:before {
        transform-origin: center;
        animation: rotate 1s linear infinite;
        margin: 0;
        vertical-align: 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}