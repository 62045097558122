
@import 'variables';
// @import '~normalize.css/normalize';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: $yellow;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@import 'base';
@import 'icon';
@import 'button';
@import 'error';
@import 'preview';
@import 'options';
@import 'upload';
@import 'form';
@import 'progress';
@import 'result';
@import 'footer';
