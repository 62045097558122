.container {
    text-align: center;
}

.header {
    margin: auto;
    max-width: 800px;
}

ul {
    max-width: 450px;
    text-align: left;
    margin: auto;
}

a {
  color: $black;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    color: $black;
  }
}

.nav--active {
  text-decoration: none;
}