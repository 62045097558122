.form-row {
    margin-bottom: 0.5rem;
}

.form__options {
    background: $yellow4;
    border-radius: 5px 5px 0 0;
    padding: 0.5rem;
}

.label__info {
    font-weight: normal;
    font-size: 0.9rem;
    display: inline-block;
    margin-left: 0.5rem;
}

.input__resolution {
    width: 50px;
    padding: 0.25rem;
    margin-left: 0.5rem;
}

.input__invert {
    margin-right: 0.5rem;
}

input[type='radio'],
input[type='checkbox'] {
    margin: auto 0.5rem;
}
