.result {
    font-size: 4px;
    line-height: 1;
    letter-spacing: 0.5em;
    margin: 2rem auto 3rem;
}

.result__canvas {
    display: block;
    margin: auto;
    max-width: 100%;
}

.result__canvas--zoom {
    max-width: none;
}