$white: #fee;
$red: #BD2F0F;

$border-radius: 3px;
$max-width: 800px;
$border-width: 2px;

$blue: #5C80BC;
$grey: #E9EDDE;
$black: #22211B;
$yellow: #FFCA0B;
$yellow2: #FFE792;
$yellow3: #FFD84C;
$yellow4: #DEAD00;
$yellow5: #A37F00;