@font-face {
    font-family: 'icomoon';
    src: url('./fonts/icomoon.eot?1ae6ga');
    src: url('./fonts/icomoon.eot?1ae6ga#iefix') format('embedded-opentype'),
        url('./fonts/icomoon.ttf?1ae6ga') format('truetype'),
        url('./fonts/icomoon.woff?1ae6ga') format('woff'),
        url('./fonts/icomoon.svg?1ae6ga#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

%icon:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    vertical-align: -15%;
    margin-right: 0.25rem;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-spinner8:before {
    @extend %icon;
    content: "\e981";
}
.icon-spinner11:before {
    @extend %icon;
    content: "\e984";
}
.icon-plus:before {
    @extend %icon;
    content: "\ea0a";
}
.icon-checkmark:before {
    @extend %icon;
    content: "\ea10";
}
.icon-arrow-up:before {
    @extend %icon;
    content: "\ea32";
}
.icon-arrow-down:before {
    @extend %icon;
    content: "\ea36";
}
.icon-arrow-left:before {
    @extend %icon;
    content: "\ea38";
}

